.Map-header-container.changedLayout {
    margin: 10px;
    width: 285px;
    height: 170px;
    border-radius: 10px;
    background-color: #1d1d1d;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    -webkit-box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 45%);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 45%);
    flex-direction: column;
    color: white;
    flex: 1 0 285px;
}

.single-subinfo-wrapper {
    width: 40px;
    height: 40px;
    border: 5px solid cornflowerblue;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 11px;
}

.single-subinfo-wrapper.activeAnimation {
	position: relative;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.single-subinfo-wrapper.activeAnimation.isUp span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 5px;
	background: linear-gradient(to right, #171618, #3bff3b);
	animation: animate1 2s linear forwards;
	animation-iteration-count: 3;
}

.single-subinfo-wrapper.activeAnimation.isDown span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 5px;
	background: linear-gradient(to right, #171618, #ff3b3b);
	animation: animate1 2s linear forwards;
	animation-iteration-count: 3;
}
@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.single-subinfo-wrapper.activeAnimation.isUp span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to bottom, #171618, #3bff3b);
	animation: animate2 2s linear forwards;
	animation-delay: 1s;
	animation-iteration-count: 3;
}
.single-subinfo-wrapper.activeAnimation.isDown span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to bottom, #171618, #ff3b3b);
	animation: animate2 2s linear forwards;
	animation-delay: 1s;
	animation-iteration-count: 3;
}
@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

.single-subinfo-wrapper.activeAnimation.isUp span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 5px;
	background: linear-gradient(to left, #171618, #3bff3b);
	animation: animate3 2s linear forwards;
	animation-iteration-count: 3;
}
.single-subinfo-wrapper.activeAnimation.isDown span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 5px;
	background: linear-gradient(to left, #171618, #ff3b3b);
	animation: animate3 2s linear forwards;
	animation-iteration-count: 3;
}
@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.single-subinfo-wrapper.activeAnimation.isUp span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to top, #171618, #3bff3b);
	animation: animate4 2s linear forwards;
	animation-delay: 1s;
	animation-iteration-count: 3;
}
.single-subinfo-wrapper.activeAnimation.isDown span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to top, #171618, #ff3b3b);
	animation: animate4 2s linear forwards;
	animation-delay: 1s;
	animation-iteration-count: 3;
}
@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}
