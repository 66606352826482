.New-container .new-order-container-child {
  width: 340px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #63839d;
  border-radius: 12px;
}

.form-control {
  margin: 10px 0 0;
}

.form-control #react-select-2-placeholder, .form-control #react-select-3-placeholder{
  color: black;
}

/* .form-control .css-1xc3v61-indicatorContainer, .form-control .css-1dimb5e-singleValue{
  color: black;
} */

.form-control.swapBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.New-container .react-datetime-picker {
  margin-top: 10px;
}

.cust-price{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.form-control .css-10zay8b.Mui-disabled {
  color: rgba(255, 255, 255, 0.9);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.35);
}