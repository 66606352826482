/* body { */
  /* display: block !important; */
  /* background: #2b2c2f; */
  /* background: #000; */
/* } */

.orderDetails {
  color: white;
  height: 100vh;
  padding: 0px 80px 40px;
  background: black;
  overflow-y: scroll;
}

.orderDetails .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 40px;
}

.orderDetails a.bookMore {
  text-decoration: none;
  color: white;
  font-size: 18px;
  border: 1px solid white;
  padding: 8px;
  border-radius: 8px;
  width: 135px;
  margin: 0 auto;
}

.wrapper-orderdetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderDetails .wrapper-main {
  width: 50%;
  padding: 0 40px 40px;
}


.orderDetails .stepper {
  padding: 40px 0 20px 0;
  width: 100%;
}

.orderDetails .mapWrapper {
  height: 75%;
}

.orderDetails .margBot10 {
  margin-bottom: 10px;
}

.animationContainer {
  display: flex;
  align-items: center;
}

.animationContainer .cardOfComplete {
  width: 50%;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 550px;
}

.animationContainer.noBusinessOrder .cardOfComplete {
  justify-content: center;
}

.cardOfComplete .downloadApp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardOfComplete .downloadApp p {
  font-size: 22px;
}

.cardOfComplete .store-buttons {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardOfComplete .store-buttons .store-btn {
  width: 50px;
  color: white;
  background: transparent;
  border: none;
  margin-right: 32px;
}

.cardOfComplete .store-buttons .store-btn {
  width: 50px;
  color: white;
  background: transparent;
  border: none;
  margin-right: 32px;
}


.animationContainer .startSoonCard {
  width: 50%;
  padding: 40px;
  text-align: center;
}

.cardOfComplete h2 {
  font-size: 72px;
}

.cardOfComplete h3 {
  font-size: 24px;
}

.cardOfComplete p {
  font-size: 18px;
}

.home-img {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 0 auto;
}

.home-img .circleMain {
  position: absolute;
  z-index: 1;
  height: 430px;
  width: 430px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
  animation: spin_01 20s linear infinite;
  background-color: transparent;
}

.home-img .circleMain img {
  max-width: 80px;
  width: 100%;
  transform: translate(219%, -50%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 32px;
}

.circle-car {
  position: absolute;
  z-index: 1;
  height: 430px;
  width: 430px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  top: 0%;
  /* // left: 100%; */
  left: 0;
  right: 0;
  margin: auto;
  /* // transform: translate(-50%, -50%); */
  animation: spin_01 20s linear infinite;
}

.circle-car img {
  max-width: 80px;
  width: 100%;
  transform: translate(219%, -50%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 32px;
}

.home-background {
  opacity: 0.9;
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 0;
  height: 380px;
  width: 445px;
}

.phone-hero {
  max-width: 250px;
  width: 100%;
  left: 90px;
  /* box-shadow: var(--shadow-black-100); */
  position: absolute;
  border-radius: 32px;
  animation: bounceTop_01 3s ease infinite;
}

@keyframes spin_01 {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}

@keyframes bounceTop_01 {

  0%,
  to {
    transform: translateY(-30px)
  }

  50% {
    transform: translateY(0)
  }
}


/* MAIN SECTION CSS */

.orderDetails .detailsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.orderDetails .details,
.orderDetails .stepperSection {
  width: 50%;
}

.stepperSection .noProgressBar {
  text-align: center;
  font-size: 20px;
}

.orderDetails .detailSection {
  display: flex;
  gap: 1em;
  align-items: center;
}

.orderDetails .flex-column.idStatusTimeSection {
  margin-right: 16px;
  width: 25%;
}

.orderDetails .clientAndDriverInfoSection {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.orderDetails .clientInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid dimgrey;
}

.orderDetails .clientName,
.orderDetails .clientAcc,
.orderDetails .service {
  width: 33.33%;
}

.orderDetails .driverName,
.orderDetails .vehicleName {
  width: 33.33%;
}

.orderDetails .driverName, .orderDetails .vehicleName {
  width: 33.33%;
  height: 85px;
}

.orderDetails .textGrey {
  margin-bottom: 8px;
  font-size: 16px;
  color: darkgrey;
  font-weight: 600;
}

.orderDetails .clientNameText {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-size: 16px;
}

.orderDetails .driverInfo {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid dimgrey;
}

.orderDetails .vertCarSVG {
  margin-right: 7px;
  width: 35px;
}

.orderDetails .StatusBar-container {
  float: none;
  margin-left: 0;
}

.orderDetails .sectionTitle {
  font-size: 18px;
  border-bottom: 2px solid white;
  width: max-content;
  margin-bottom: 8px;
}

.moreInfoBtn {
  text-align: center;
}



/* Modal CSS */
.wrapperOfModal {
  background: black;
  color: white;
  border: 1px solid white !important;
}

.wrapperOfModal .dateInfo,
.wrapperOfModal .addressInfo,
.wrapperOfModal .airportInfo,
.wrapperOfModal .clientInfo,
.wrapperOfModal .noteInfo {
  border-bottom: 1px solid grey;
  padding: 16px;
}

.wrapperOfModal .clientInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapperOfModal .dateInfo {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 10px;
  /* font-size: 18px; */
}

.wrapperOfModal .dateInfo p {
  font-size: 16px;
}

.wrapperOfModal .airportInfo {
  display: flex;
  align-items: center;
  gap: 2em;
}

.airportInfo .StatusBar-container {
  float: none;
  margin: 0;
}

.wrapperOfModal .clientInfo div h6 {
  font-size: 16px;
}

.wrapperOfModal .mailInfo {
  font-size: 16px;
  text-align: center;
}

.wrapperOfModal .notesInput {
  width: 100%;
}

.orderDetails .carText {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}



.businessOrderList .moreInfoBtn {
  margin: 20px 0;
}

.orderDetails.businessOrderList .detailsWrapper {
  border-bottom: 1px solid grey;
}

.orderDetails.businessOrderList .businessOrderListFilterBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: 0 0 16px;
}

.orderDetails.businessOrderList .businessOrderListFilterBtns button {
  border: 2px solid;
}

.orderDetails.businessOrderList .wrapSelectAndBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.orderDetails.businessOrderList .wrapSelectAndBtns .wrappper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderDetails.businessOrderList .wrapSelectAndBtns .wrappper button {
  margin-left: 16px;
}

.orderDetails.businessOrderList #react-select-2-listbox {
  background: black;
  z-index: 100000;
}

.orderDetails.businessOrderList .selectForBusinessAdmin {
  width: 350px;
}

.orderDetails.businessOrderList .css-d7l1ni-option {
  color: black;
}

.dialogCustonForMoreInfo .viaLIs {
  height: auto;
  line-height: 1.5;
}

.wrapperOfModal .wrapup p{
  color: darkgrey;
}

.wrapperOfModal .wrapup h6{
  color: white;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  width: 700px;
}

/* .orderDetails */

@media only screen and (max-width: 1024px) {
  .orderDetails {
    padding: 0px 40px 40px;
  }

  .animationContainer {
    overflow: hidden;
  }
}

@media only screen and (max-width: 994px) {
  .animationContainer .cardOfComplete {
    padding: 30px;
  }

  .cardOfComplete h2 {
    font-size: 44px;
    margin-bottom: 16px;
  }

  .cardOfComplete h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .cardOfComplete p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 994px) {
  .orderDetails {
    padding: 0 40px;
  }

  .wrapper-orderdetails {
    display: block;
  }

  .orderDetails .wrapper-main {
    width: 100%;
  }

  .animationContainer {
    flex-direction: column-reverse;
    padding-top: 50px;
  }

  .orderDetails .stepper {
    width: 100%;
  }

  .home-img {
    width: 430px;
    height: 430px;
  }

  .animationContainer .cardOfComplete {
    width: 100%;
  }
}

@media only screen and (max-width: 994px) {
  .orderDetails .detailsWrapper {
    display: block;
  }

  .orderDetails .details,
  .orderDetails .stepperSection {
    width: 100%;
  }

  .orderDetails .moreInfoBtn {
    margin-top: 16px;
  }

}

@media only screen and (max-width: 576px) {
  .orderDetails .iconToHide {
    display: none;
  }

  .orderDetails .topbar {
    padding: 0;
  }

  .cardOfComplete h2 {
    font-size: 36px;
    margin-bottom: 12px;
  }

  .cardOfComplete h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .cardOfComplete p {
    font-size: 16px;
  }

  .home-img {
    width: 300px;
    height: 300px;
  }

  .home-img .circleMain {
    height: 300px;
    width: 300px;
    top: 0%;
    left: 0%;
  }

  .home-img .circleMain img {
    max-width: 50px;
  }

  .phone-hero {
    max-width: 180px;
    width: 100%;
    left: 62px;
  }

  .home-background {
    top: 25px;
    left: 9px;
    height: 250px;
    width: 300px;
  }

  .animationContainer .cardOfComplete {
    height: auto;
  }

  .animationContainer {
    padding-top: 30px;
  }

  .cardOfComplete .downloadApp p {
    font-size: 16px;
  }

  .cardOfComplete .store-buttons .store-btn {
    width: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .orderDetails.businessOrderList .wrapSelectAndBtns {
    display: block;
  }

  .orderDetails.businessOrderList .wrapSelectAndBtns .wrappper {
    margin: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .orderDetails {
    padding: 0 30px;
  }


  .orderDetails .mapWrapper {
    height: 65%;
  }

  .wrapper-orderdetails {
    height: 35%;
  }

  .orderDetails .wrapper-main {
    padding: 0;
  }

  .orderDetails .flex-column {
    width: 24%;
  }

  .orderDetails .flex-column h6 {
    font-size: 16px;
  }

  .orderDetails .flex-column p {
    font-size: 12px;
  }

  .orderDetails .flex-column span {
    font-size: 13px;
  }

  .orderDetails .flex-column button {
    font-size: 12px;
    padding: 3px 8px;
    margin-top: 10px;
  }

  .orderDetails .flex-column .StatusBar-container {
    margin: 10px 0;
    min-width: 100px;
  }

  .orderDetails .flex-column p h6 {
    font-size: 13px;
  }

  .orderDetails .flex-column .vertCarSVG {
    margin-right: 5px;
    width: 30px;
  }

  .orderDetails .margBot10 {
    margin-bottom: 8px;
  }

  .orderDetails.businessOrderList .detailsWrapper {
    padding-top: 16px;
    border: 1px solid grey;
    border-radius: 10px;
  }

  .orderDetails.businessOrderList .flex-column {
    height: 100%;
  }

  .orderDetails.businessOrderList .detailSection {
    height: auto;
  }

  .orderDetails.businessOrderList .stepper {
    padding: 50px 0 20px 0;
  }

  .orderDetails.businessOrderList .stepperSection {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 524px) {
  .orderDetails {
    padding: 0 30px;
    padding: 0 15px;
  }

  .orderDetails .detailSection {
    gap: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 325px;
  }

  .orderDetails .flex-column.idStatusTimeSection {
    margin-right: 0px;
    width: 60%;
  }

  .orderDetails .clientAndDriverInfoSection {
    width: 100%;
  }

  .orderDetails .driverName,
  .orderDetails .vehicleName {
    width: 33.33%;
  }

  .dialogCustonForMoreInfo .MuiPaper-elevation {
    margin: 16px;
  }

  .wrapperOfModal .airportInfo {
    gap: 1em;
  }

  .wrapperOfModal .clientInfo h6 {
    font-size: 14px;
  }

  .orderDetails .stepperSection {
    width: 100%;
    margin-top: 16px;
  }

  .wrapperOfModal .dateInfo,
  .wrapperOfModal .addressInfo,
  .wrapperOfModal .airportInfo,
  .wrapperOfModal .clientInfo,
  .wrapperOfModal .noteInfo {
    padding: 16px 0px;
  }

  .wrapperOfModal .clientInfo {
    flex-wrap: wrap;
    gap: 1em;
  }

  .wrapperOfModal .clientInfo div h6 {
    font-size: 14px;
  }

  .wrapperOfModal .wrapup {
    flex: 1 0 30%;
  }
}

@media only screen and (max-width: 425px) {
  .orderDetails .clientNameText {
    font-size: 14px;
  }

  .orderDetails .driverInfo h6 {
    font-size: 14px;
  }

  .orderDetails .carText {
    font-size: 14px;
  }

  .wrapperOfModal .wrapup p {
    font-size: 14px;
  }
}

.css-eglki6-MuiLinearProgress-root{
  position: absolute;
}

.some-custom-overlay-class .slide-pane__content {
  padding: 0;
}

.react-calendar{
  color: #000;
}
.toolbar{
  background-color: #000;
}