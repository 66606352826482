@font-face {
  font-family: KyrialSans-light;
  src: url(../public/assets/fonts/KyrialSansProFamily/KyrialSansPro-Light.otf);
}

@font-face {
  font-family: KyrialSans-regular;
  src: url(../public/assets/fonts/KyrialSansProFamily/KyrialSansPro-Regular.otf);
}
@font-face {
  font-family: KyrialSans-bold;
  src: url(../public/assets/fonts/KyrialSansProFamily/KyrialSansPro-Bold.otf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style-type: none;
}

select {
  background-image: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right;
  -webkit-appearance: none;
  appearance:none;
  background-position-x: 24px;
}

/* LOGIN PAGE */

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.Login-form {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Login-terms-container {
  padding: 10px;
  font-size: 14px;
}

.Login-terms {
  font-family: KyrialSans-regular;
}

.Login-disclaimer {
  font-family: KyrialSans-regular;
  padding-top: 40px;
  text-align: center;
  font-size: 14px;
}

.App-header {
  background-color: #434343;
  min-height: 100vh;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* CHECKBOX */

.CheckBox-input {
  border: 0px;
  clip: rect(0 0 0 0);
  /* clippath: inset(50%); */
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.CheckBox-container {
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}

.CheckBox-icon {
  fill: none;
  stroke-width: 2px;
}

.CheckBox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
}

/* FIELD */

.FormField {
  margin-top: 10px;
  width: 100%;
  color: #000000;
  font-family: KyrialSans-light;
  border-color: #000000;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  background-color: #f2f2f2;
}

.FormField__Input {
  width: 100%;
  background-color: transparent;
  border: none;
  color: #000000;
  outline: none;
  font-size: 18px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-top: 10px;
  font-family: KyrialSans-light;
}
.FormField__Input::placeholder {
  color: black;
}

/* OPACITY BACKGROUND */

.Opacity-background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 1);
  z-index: 100000;
}

/* NEW ORDER COMPONENT */

.New-container {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0px;
  gap:20px;
  color: #000;
}

.New-title {
  padding-top: 10px;
  font-size: 18px;
  font-family: KyrialSans-bold;
  color: #000000;
}

.Assign-estimation-wrapper {
  margin-top: 20px;
}

/* ASSIGN COMPONENT */

.Assign-container {
  display: flex;
  flex-direction: column;
  width: 306px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
}

.Assign-title {
  font-size: 24px;
  font-family: KyrialSans-bold;
  color: #000000;
}

.Assign-estimation-text {
  font-size: 21px;
  font-family: KyrialSans-regular;
  color: #000000;
}

.Assign-estimation {
  font-size: 21px;
  font-family: KyrialSans-bold;
  color: #000000;
}

.header-add-via{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.add-remove-btn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}
/* ORDER POPUP COMPONENT */

.OrderPopUp-container {
  display: flex;
  flex-direction: column;
  width: 606px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
}

.Order-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.OrderPopUp--header-container {
  display: flex;
  justify-content: space-between;
}

.OrderPopUp--header-text {
  font-size: 21px;
  color: #000000;
  font-family: KyrialSans-bold;
}

.OrderPopUp--title {
  font-size: 24px;
  font-family: KyrialSans-bold;
  color: #000000;
}

.OrderPopUp-estimation-text {
  font-size: 21px;
  font-family: KyrialSans-regular;
  color: #000000;
}

.OrderPopUp-estimation {
  font-size: 21px;
  font-family: KyrialSans-bold;
  color: #000000;
}

.OrderPopUp--header-container {
  display: flex;
  justify-content: space-between;
}

.OrderPopUp--header-text {
  font-size: 21px;
  color: #000000;
  font-family: KyrialSans-bold;
}

.OrderPopUp--name-text {
  font-size: 21px;
  color: #000000;
  font-family: KyrialSans-bold;
  letter-spacing: 2.3px;
}

.OrderPopUp--item-container {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.OrderPopUp--item-description {
  padding-left: 10px;
  font-size: 14px;
  color: #000000;
  font-family: KyrialSans-regular;
}

.OrderPopUp-status-container {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
}

.OrderPopUp-price-container {
  padding-top: 25px;
  text-align: end;
}

/* HOME */

.Home-container {
  display: flex;
  width: 100%;
  overflow-y: hidden;
  height: 100vh;
}

/* SIDEBAR */

/* .Sidebar-container {
  display: flex;
  flex-direction: column;
  width: 75vw;
} */

/* AVATAR */

.Avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  min-width: 45px !important;
}

/* HEADER */

.Header-cards-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

}

.Header-switcher-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Header-switcher-bullet {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.Header-switcher-text {
  color: #ffffff;
  margin-left: 10px;
  width: 60px;
  font-family: KyrialSans-regular;
}
.Header-switcher-text.newUiOnOffText{
  color: #000;
}

.Header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Header-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header-time-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
}

.Header-time {
  font-size: 14px;
  color: #ffffff;
  font-family: KyrialSans-light;
}

/* TABS */

.Tabs-container {
  display: flex;
  background-color: #2e2e2e;
}

.Tab-container {
  border: 1px solid #424242;
  height: 47px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Tab-title {
  font-size: 14px;
  color: #ffffff;
  font-family: KyrialSans-regular;
}

.Tab-active {
  background-color: #1d1d1d;
}

/* SEARCH BAR */

/* .Searchbar-container {
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.5);
  height: 47px;
  background-color: #434343;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
} */

.Searchbar-input {
  padding-left: 10px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-family: KyrialSans-regular;
  background-color: transparent;
  border: none;
  outline: none;
}

.DatePicker-input > .react-datetime-picker__wrapper {
  margin-bottom: 0 !important;
  height: 30px;
  border: 2px solid transparent;
  background-color: rgb(111, 111, 111);
}

.DatePicker-input .react-datetime-picker__inputGroup  input, .DatePicker-input .react-datetime-picker__inputGroup span{
  color: #ffffff !important;
}

/* BUTTON */

.Button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}

.Button-container--with-shadow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  border-radius: 10px;
}

.Button-container :hover {
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  border-radius: 10px;
}

.Button-text {
  width: 100%;
  font-size: 16px;
  font-family: KyrialSans-bold;
  color: #ffffff;
  align-self: center;
  text-align: center;
}

.filtersTabs {
  background: #434343;
  display: flex;
  align-items: center;
  width: 100%;
}

.filtersTabs .selectForOrderList{
  width: 25%;
  display: flex;
  align-items: center;
}
.filtersTabs .selectForOrderList .selectForOrderListSelect{
  width: 100%;
}
/* .filtersTabs .selectForOrderList .clearIconBox {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  color: #b5afaf;
}
.filtersTabs .selectForOrderList .clearIconBox:hover {
  color: #000;
  cursor: pointer;
} */
/* ORDER */

.Order-wrapper {
  background-color: #1d1d1d;
}

.Order-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.active {
  box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.Order-name-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.Order-header-container {
  display: flex;
  justify-content: space-between;
}

.Order-header-text {
  font-size: 21px;
  /* color: #ffffff; */
  font-family: KyrialSans-bold;
}

.Order-name-text {
  font-size: 21px;
  /* color: #ffffff; */
  font-family: KyrialSans-bold;
  letter-spacing: 2.3px;
}

.Order-name-text-sm {
  font-size: 16px;
  /* color: #ffffff; */
  font-family: KyrialSans-bold;
  margin: 3px 0;
}


.Order-item-container {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.Order-item-description {
  padding-left: 10px;
  font-size: 14px;
  color: #ffffff;
  font-family: KyrialSans-regular;
}

.accordian-deatails .Order-item-description {
  color: #000;
  font-family: KyrialSans-regular;
}

.Order-status-container {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
}

.Order-price-container {
  padding-top: 25px;
  text-align: end;
}

.Order-price {
  font-size: 24px;
  color: #ffffff;
  font-family: KyrialSans-bold;
}

/* OPTIONS */

.Options-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
}

/* STATUS CARD */
.first-box, .time-details , .driver-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 150px;
  align-items: center;
}

.StatusBar-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  float: right;
  width: auto;
  height: 25px;
  border-radius: 5px;
  margin-left: 12px;
  min-width: 114px;
}

.first-box .StatusBar-container {
  margin-left: 0;
}

#panel1a-header .StatusBar-container, .client-info-box .StatusBar-container {
  margin-left: 0;
  margin-bottom: 0px;
  float: none;
}

.StatusBar-text {
  font-size: 14px;
  color: #ffffff;
  font-family: KyrialSans-regular;
}

/* LOADIN BAR */

.LoadingBar {
  height: 17px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#444444+0,8e71ff+100 */
  background: rgb(68, 68, 68); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(68, 68, 68) 0%,
    rgb(142, 113, 255) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(68, 68, 68) 0%,
    rgb(142, 113, 255) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(68, 68, 68) 0%,
    rgb(142, 113, 255) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#8e71ff',GradientType=1 ); /* IE6-9 */
}

.LoadingBar2 {
  height: 17px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#444444+0,8e71ff+100 */
  background: rgb(68, 68, 68); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(68, 68, 68) 0%,
    rgb(142, 113, 255) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(68, 68, 68) 0%,
    rgb(142, 113, 255) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(68, 68, 68) 0%,
    rgb(142, 113, 255) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#8e71ff',GradientType=1 ); /* IE6-9 */
}

/* BUTTON ASSIGN */

.Button-assign-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
}

.Button-assign-content :hover {
  background-color: #dfd3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Button-assign-text {
  font-size: 16px;
  color: #000000;
  font-family: KyrialSans-regular;
  width: 100%;
  text-align: center;
}

/* ICON */
.Icon-container {
  padding: 20px;
}

.Icon {
  width: 24px;
  height: 24px;
}

/* NOTIFICATION */

.Notification-card {
  width: 326px;
  height: 66px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
}

.Alert-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,2e2e2e+100 */
  background: rgb(255, 0, 0, 0.8); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(255, 0, 0, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(255, 0, 0, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(255, 0, 0, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#2e2e2e',GradientType=1 ); /* IE6-9 */
}

.Alert-gradient:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,2e2e2e+100 */
  background: rgb(255, 0, 0); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(255, 0, 0) 0%,
    rgb(46, 46, 46) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(255, 0, 0) 0%,
    rgb(46, 46, 46) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(255, 0, 0) 0%,
    rgb(46, 46, 46) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#2e2e2e',GradientType=1 ); /* IE6-9 */
}

.Success-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00fb09+0,2e2e2e+100 */
  background: rgb(0, 251, 9, 0.8); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(0, 251, 9, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(0, 251, 9, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(0, 251, 9, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fb09', endColorstr='#2e2e2e',GradientType=1 ); /* IE6-9 */
}

.Success-gradient:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00fb09+0,2e2e2e+100 */
  background: rgb(0, 251, 9); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(0, 251, 9) 0%,
    rgb(46, 46, 46) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(0, 251, 9) 0%,
    rgb(46, 46, 46) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(0, 251, 9) 0%,
    rgb(46, 46, 46) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fb09', endColorstr='#2e2e2e',GradientType=1 ); /* IE6-9 */
}

.Default-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5b5b5b+0,2e2e2e+100 */
  background: rgb(91, 91, 91, 0.8); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(91, 91, 91, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(91, 91, 91, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(91, 91, 91, 0.8) 0%,
    rgb(46, 46, 46, 0.8) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b5b5b', endColorstr='#2e2e2e',GradientType=1 ); /* IE6-9 */
}

.Default-gradient:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5b5b5b+0,2e2e2e+100 */
  background: rgb(91, 91, 91); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgb(91, 91, 91) 0%,
    rgb(46, 46, 46) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgb(91, 91, 91) 0%,
    rgb(46, 46, 46) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgb(91, 91, 91) 0%,
    rgb(46, 46, 46) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b5b5b', endColorstr='#2e2e2e',GradientType=1 ); /* IE6-9 */
}

/* MAP HEADER CONTAINER */

/* .Map-header-cards-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  padding-bottom: 10px;
  overflow-x: scroll;
} */

/* MAP HEADER CARD */

.Map-header-container {
  margin: 10px;
  width: 285px;
  border-radius: 10px;
  background-color: #1d1d1d;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.45);
  flex-direction: column;
  color: white;
}

.Map-header-value-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Map-header-value {
  font-size: 48px;
  color: #ffffff;
  font-family: KyrialSans-bold;
}

.Map-header-title-container {
  display: flex;
  flex-direction: column;
}

.Map-header-title {
  font-size: 40px;
  color: #ffffff;
  font-family: KyrialSans-regular;
  text-align: right;
}

.Map-header-subtitle {
  font-size: 24px;
  color: #ffffff;
  font-family: KyrialSans-regular;
  font-weight: bold;
  text-align: right;
  line-height: 10px;
}

.Popup-card {
  width: 230px;
  height: 58px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
}

/* MAP MARKER CARD */

.Marker-container {
  width: 230px;
  height: 52px;
  background-color: #2e2e2e;
  border-radius: 6px;
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.45);
}

.Marker-avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.Marker-avatar {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.Marker-description-container {
  display: flex;
  flex-direction: column;
}

.Marker-description-title {
  font-size: 12px;
  color: #ffffff;
  font-family: KyrialSans-bold;
}

.Marker-description-text {
  font-size: 12px;
  color: #ffffff;
  font-family: KyrialSans-regular;
}

.Marker-eta-container {
  background-color: #1d1d1d;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 15px 7px 15px;
}

.Marker-eta-title {
  font-size: 18px;
  color: #ffffff;
  font-family: KyrialSans-bold;
}

.Marker-eta-text {
  font-size: 12px;
  color: #ffffff;
  font-family: KyrialSans-bold;
}

/* SWITCH TOGGLE */

.Switch {
  height: 0;
  width: 0;
  visibility: hidden;
}

.Switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 19px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.Switch-label .Switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  transition: 0.2s;
  background: #ffffff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.Switch:checked + .Switch-label .Switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

/* RIPPLE */

.Ripple-circle-container {
  position: absolute;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  list-style: none;
  opacity: 1;
}

.circle {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ripples 4s infinite;
  transform: scale(0);
}

.circle:nth-child(1) {
  background: #ff5050;
}

.circle:nth-child(2) {
  background: #f70000;
  animation-delay: 2s;
}

.circle:nth-child(3) {
  background: #ff6c6c;
  animation-delay: 3s;
}

@keyframes ripples {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* REACT DATE TIME COMPONENT */

.react-datetime-picker__wrapper {
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  width: 100%;
  color: #000000;
  font-family: KyrialSans-light;
  border-color: #000000;
  border-radius: 5px;
  border-width: 0px;
  border-style: solid;
  overflow: hidden;
  background-color: #f2f2f2;
}

.react-datetime-picker__inputGroup__input {
  font-size: 18px;
}

/* REACT DROPDOWN CHECBOX COMPONENT */

.multi-select {
  --rmsc-main: transparent;
  --rmsc-hover: #f2f2f2;
  --rmsc-selected: #f2f2f2;
  --rmsc-border: #f2f2f2;
  --rmsc-gray: #f2f2f2;
  --rmsc-bg: #f2f2f2;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

.dropdown-heading-dropdown-arrow {
  display: none;
}

.go2139093995 .gray {
  font-family: KyrialSans-light;
  font-size: 18px;
  color: #000000;
}

.dropdown-heading-value {
  font-family: KyrialSans-light;
  font-size: 18px;
  color: #000000;
}

.Header-switcher-container {
  justify-content: space-between;
}

.Header-switcher-container > div:first-child {
  flex: 1;
  display: flex;
  align-items: center;
}

.Header-switcher-container > div a {
  color: #ffffff;
  text-decoration: none;
}

/* slider css */

.slide-pane__content {
  padding: 0;
}

.sliding-header-part {
  background: #141414;
  padding: 15px;
  color: #fff;
  margin-bottom: 15px;
  position: relative;
}
.sliding-header-part h3 {
  margin: 0;
}
.sliding-header-part .close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  cursor: pointer;
}
.sliding-header-sos-part {
  background: red;
  padding: 15px;
  color: #fff;
  margin-bottom: 15px;
  position: relative;
}
.sliding-header-sos-part h3 {
  margin: 0;
}
.sliding-header-sos-part .close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  cursor: pointer;
}
.close-icon svg {
  width: 18px;
  height: 18px;
}
.user-details {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
}
.user-details ul {
  list-style-type: none;
  padding: 0;
}
.user-details ul h3{
  margin-bottom: 15px;
}

.order-details {
  padding: 15px;
}
.order-details ul {
  border: 1px solid #f1f1f1;
  margin: 0;
  box-shadow: 2px 3px #f1f1f1;
  list-style-type: none;
  padding: 15px 15px;
}
.order-details ul h3{
  margin-bottom: 15px;
}
.order-details label {
  font-weight: bold;
  color: #2d2d2d;
}
.payment-method-group {
  margin-top: 10px;
  width: 100%;
  color: #000000;
  font-family: KyrialSans-light;
  overflow: hidden;
  border-radius: 5px;
}

.payment-method-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: var(--shadow-black-100);
  background-color: #fff;
  border-radius: 10px;
  padding: 0 24px;
  color: #000;
  transition: all 0.5s ease;
  margin-bottom: 5px;
  cursor: pointer;
}
.payment-method-item:focus {
  outline: none !important;
}
.payment-method-item .details {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
}
.payment-method-item .logo {
  width: 50px;
  height: 50px;
}
.payment-method-item .description {
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 0px;
  color: #000;
}

.check-icon {
  height: 20px;
  width: 20px;
  color: var(--dark-to-main-color);
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.check-icon .fontAwesome {
  line-height: 40px;
}


/* Custom flex */

.flex-column{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 20%;
}

.first-box.acordionDetailsIcons{
  justify-content: space-between;
}

.flex-column .blackBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 165px;
  min-height: 70px;
  height: max-content;
  background-color: #000;
  border-radius: 5px;
  text-align: center;
}

.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.css-1187icl {
    justify-content: center;
    padding: 0 20px;
}

.css-bqmlqf .MuiAccordionSummary-content {
  margin: 0;
}

.sidebar-secondLayout{
  display: flex;
}

.flex-column .time-left{
  width: 100%;
  text-align: center;
}

.flex-column.icons-wrapper-accordian{
  width: auto;
  margin: 0 15px;
}

.flex-column.icons-wrapper-accordian svg {
  font-size: 20px;
}

.Button-assign-content.custom-assign-driver {
margin-top: 10px;
height: 30px;
border-radius: 6px;
}
.Button-assign-content.custom-assign-driver :hover {
  border-radius: 6px;
  }

.sidebar-sidecontent{
  background-color: #1D1D1D;
  color: #cccccc;
  width: 100px;
  z-index: 10;
}

/* Accordian css */
.time-miles-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2;
  color: red;
}

.time-miles-wrapper .iconsWrapper {
  display: flex;
  flex-direction: column;
}

.time-miles-wrapper .iconsWrapper .red-dot,
.time-miles-wrapper .iconsWrapper .green-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 5px;
}

.red-dot{
  background-color: red;
}

.green-dot{
  background-color: green;
}

.client-details .StatusBar-container{
  float: none;
  margin: 0;
  width: 140px;
  padding: 0;
}

.flex-column.accordian-schedule-s-d{
  width: auto;
}

.flex-column .IconText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.IconText.isHourly{
  align-items: flex-start;
}

 .single-book-detail .IconText{
  display: flex;
  align-items: flex-start;
}

.single-book-detail .extra-info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-book-detail .extra-info .StatusBar-container{
  margin-bottom: 10px;
}

.flex-column .IconText p, .single-book-detail .IconText p{
  font-size: 16px;
  font-weight: bold;
}

.date-details .single-book-detail, .client-details .single-book-detail, 
.route-details .single-book-detail, .price-details .estimatedPrice, 
.price-details .capturedContainer>div{
  margin-bottom: 10px;
}

.single-book-detail.booked-for-time{
  color: mediumspringgreen;
}

.route-details .single-book-detail h6{
  font-size: 14px;
}

.route-details .single-book-detail .viaSpan{
  color: orangered;
  margin-right: 5px;
}

.single-book-detail .dflexRoute{
  display: flex;
  padding-left: 0;
}

.phoneNumberWrapper{
  display: flex;
  align-items: center;
}

.single-book-detail .dflexRoute .estimated, .single-book-detail .dflexRoute .actual{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column .vertCarSVG{
  margin-right: 7px;
  width: 35px;
}

.dflexRoute .estimated p, .dflexRoute .actual p{
  font-size: 16px;
  font-weight: 700;
}

.upper-block{
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.upper-block  h2{
  padding-left: 20px;
}

.additionalPrice div{
  margin-left: 20px;
}

.estimatedPrice p, .additionalPrice p{
  font-size: 14px;
}

.additionalPrice div p span{
  color: red;
}

.estimatedPrice h2{
  font-size: 16px;
}

.capturedContainer p{
  font-size: 14px;
}

.capturedContainer h2{
  font-size: 16px;
}

.single-book-detail p{
  font-size: 14px;
}

.single-book-detail div{
  padding-left: 20px;
}

.single-book-detail div h6{
  font-size: 16px;
}

.co2-details h5{
  margin-left: 20px;
}

.box-with-border{
  width: 40px;
  height: 40px;
  border: 5px solid;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/* Stepper custom */
.stepperParent{
  position: relative;
}

.stepperParent .labelChild{
  position: absolute;
  top: -52px;
  /* left: -20px; */
  text-align: center;
}

.stepperParent .css-2fdkz6.MuiStepLabel-alternativeLabel{
  margin-top: 8px;
}

.sideFilterDrop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  margin-bottom: 15px;
}

.sideFilterDrop p{
  border-bottom: 1px solid white;
  font-weight: 700;
  color: white;
  text-align: center;
  font-size: 11px;
  padding: 10px 2px;
  line-height: 7px;
}

.notesInput {
  width: 50%;
  padding: 10px;
  border: 1px solid red;
  border-radius: 7px;
  background: transparent;
  color: white;
}
.notesInputDispatcher {
  width: 50%;
  padding: 10px;
  border: 1px solid orange;
  border-radius: 7px;
  background: transparent;
  color: white;
}
.noteToDriverTextArea {
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  color: black;
  resize: vertical;
  outline: none;
  font-size: 18px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-family: KyrialSans-light;
}
.dispatcherNotesWrapper{
  display: flex;
  align-items: center;
}
.dispatcherNotesWrapper svg{
  font-size: 24px;
    margin-left: 5px;
}
/* Progress bar css */
.css-7p5u54 {
  position: absolute !important;
}

.highway {
  height: 200px;
  width: 1000%;
  display: block;
  background-image: url('https://i.imgur.com/bVXQ8P5.jpg');
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-repeat: repeat;
  animation: highway 5s linear infinite;
}

@keyframes highway {
  100% {
    transform: translateX(-3000px);
  }
}

.city {
  height: 250px;
  width: 500%;
  background-image: url('https://i.imgur.com/Q6bTT2k.png');
  position: absolute;
  left: 0;
  right: 0;
  bottom: 200px;
  display: block;
  z-index: 1;
  background-repeat: repeat;
  animation: city 20s linear infinite;
}

@keyframes city {
  100% {
    transform: translateX(-1500px);
  }
}

.carIcon{
  position: relative;
  /* top: -68px;
  left: 0px; */
  top: -17px;
  left: 25px;
}

.carBody{
  width: 50px;
  position: absolute;
}

.carBody .carBodyImg {
  width: 40px;
  height: auto;
  animation: car 1s linear infinite;
}


@keyframes car {
  0% {
    transform: translateY(-1px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-1px);
  }
}

.wheelWrapper {
  z-index: 2;
  width: 10px;
}

.wheelWrapper img {
  width: 8px;
  height: 8px;
  animation: wheel 1s linear infinite;
  top: 8px;
}

@keyframes wheel {
  100% {
    transform: rotate(360deg);
  }
}

.frontwheel1 {
  position: absolute;
  left: 28px;
}

.backwheel1 {
  position: absolute;
  left: 3px;
}

.car {
  width: 50px;
  /* left: 50%; */
  bottom: 72px;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
}

.car img {
  width: 50px;
  animation: car 1s linear infinite;
}

@keyframes car {
  0% {
    transform: translateY(-1px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-1px);
  }
}

.wheel {
  /* left: 50%; */
  transform: translateX(-50%);
  position: absolute;
  top: -63px;
  z-index: 2;
}

.wheel img {
  width: 10px;
  height: 10px;
  animation: wheel 1s linear infinite;
}

@keyframes wheel {
  100% {
    transform: rotate(360deg);
  }
}

.notesAndReorderBtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.notes-wrapper{
  width: 100%;
}
.frontwheel {
  position: absolute;
  left: 10px;
}

.backwheel {
  position: absolute;
  left: -21px;
}

.eta-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #cdcccc;
  border-radius: 5px;
  padding: 5px;
  width: 30px;
  /* top: -68px;
  left: 43px; */
  top: -23px;
  left: 70px;
  font-weight: 700;
  color: black;
  /* z-index: 1; */
}

.eta-wrapper .eta-mins{
  font-size: 14px;
  font-weight: 700;
}
.eta-wrapper .eta-text{
  font-size: 12px;
}

b li::marker, .markerColor li::marker {
  color: blue;
  font-size: 1.2em;
}
b li{
  margin-left: '8px';
}
b li:first-child::marker{
  color: green;
  font-size: 1.5em;
}
b li:last-child::marker{
  color: red;
  font-size: 1.5em;
}
.addressWrapper{
  padding: 0 30px;
  list-style: initial;
}
.sourceLi, .destLi{
  list-style: initial;
}

.sourceLi::marker {
  color: green;
  font-size: 1.5em;
}
.destLi::marker {
  color: red;
  font-size: 1.5em;
}

/* Logs */
.pickUpLog, .destLog{
  display: flex;
  align-items: flex-start;
  font-weight: 700;
}
.pickUpLog > span, .destLog > span{
  margin-left: 5px;
}

.viaLIs {
  height: 26px;
  line-height: 1;
}

.custom-tooltip-1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

span.minutesInTooltip {
  background: white;
  padding: 3px;
  font-size: 13px;
  border-radius: 5px;
}

.selectedSingleMap .tooltiptext-arrived, .selectedSingleMap .tooltiptext-pob, .selectedSingleMap .tooltiptext-enroute {
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  z-index: 1;
  /* bottom: 20px;
  left: 50%;
  margin-left: -24px; */
  bottom: 70px;
  left: 15px;
  margin-left: -34px;
  width: max-content;
  color: black;
  font-weight: bolder;
  font-size: 12px;
}

.selectedSingleMap .tooltiptext-arrived {
  background-color: #FF6105;
}

.selectedSingleMap .tooltiptext-pob {
  background-color: #267AFF;
}

.selectedSingleMap .tooltiptext-enroute {
  background-color: #0FB4B6;
}

.selectedSingleMap .tooltiptext-arrived::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FF6105 transparent transparent transparent;
}

.selectedSingleMap .tooltiptext-pob::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #267AFF transparent transparent transparent;
}

.selectedSingleMap .tooltiptext-enroute::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #0FB4B6 transparent transparent transparent;
}

.custom-tooltip-1 .tooltiptext-arrived, .custom-tooltip-1 .tooltiptext-pob, .custom-tooltip-1 .tooltiptext-enroute {
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  z-index: 1;
  /* bottom: 20px;
  left: 50%;
  margin-left: -24px; */
  bottom: 20px;
  left: -5px;
  margin-left: -34px;
  width: max-content;
  color: black;
  font-weight: bolder;
  font-size: 12px;
}

.custom-tooltip-1 .tooltiptext-arrived::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FF6105 transparent transparent transparent;
}

.custom-tooltip-1 .tooltiptext-pob::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #267AFF transparent transparent transparent;
}

.custom-tooltip-1 .tooltiptext-enroute::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #0FB4B6 transparent transparent transparent;
}

.alertCustom.toast{
  padding: 7px 0;
}

.alertCustom.toast .idAndDate, .alertCustom.toast .driverInfo{
  height: 40px;
}

.alertCustom.toast .idAndDate h6, .alertCustom.toast .driverInfo h6{
  font-size: 15px;
}

.alertCustom.toast .idAndDate p, .alertCustom.toast .driverInfo p{
  font-size: 13px;
}

/* Tracking details page */
.wrapper-main{
  display: flex;
}

.badgeAnimation {
  /* scale: 1.2; */
  animation: sclaeInOut 1s infinite  forwards ;
}

.infoAnimation > path {
  /* scale: 1.2; */
  animation: sclaeInOutOnly 1s infinite  forwards ;
}

@keyframes sclaeInOutOnly {
  0% {
   transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
   transform: scale(1);
  }
}

.selectImageForm {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.selectImageFormAccordian{
  width: 25px;
  height: 25px;
}

.iconsOfOPtherOption {
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.flightIconAndNumber{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.mobileView .StatusBar-container {
  min-width: min-content;
  padding: 0px 10px;
}

@keyframes sclaeInOut {
  0% {
   transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
   transform: scale(1);
  }
}

.estTimeMiles {
  text-align: end;
  color: rgb(102, 26, 37);
  font-weight: 700;
}

/* For new ui changes */

@media only screen and (max-width: 576px) {
  .first-box.acordionDetailsIcons {
    width: 100%;
    justify-content: space-evenly;
  }
}

/* latest Chakra custom css */

.css-4t6ey5[data-orientation=vertical] {
  max-height: calc(100% - var(--stepper-indicator-size)) !important;
  top: calc(var(--stepper-indicator-size) + 8px) !important;
  /* max-height: 20px; */
}

.css-1nmdiq5-menu {
  z-index: 10000 !important;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

/* Custom styles for disabled dates */
.react-calendar__tile:disabled {
  background-color: #f0f0f0 !important;
  color: #ccc !important;
  cursor: not-allowed;
}
/* 
.react-select__indicators{
  color: black
} */