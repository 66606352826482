.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
}

.conversation-list-item.active {
  background-color: #44484b;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-status-online {
  color: green;
  top: 4px;
  left: 57px;
  position: absolute;
}

.conversation-status-offline {
  color: red;
  top: 4px;
  left: 57px;
  position: absolute;
}

.conversation-unread-messages {
  left: 300px;
  padding: 2px;
  color: #fff;
  font-size: 11px;
  min-width: 12px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #417aff;
  background-color: #417aff;
}