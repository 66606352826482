/* body { */
/* display: block !important; */
/* background: #2b2c2f; */
/* background: #000; */
/* } */

.orderDetails {
  color: white;
  height: 100vh;
  /* padding: 0px 80px 40px; */
  background: black;
  overflow-y: scroll;
}

.Home-container.orderDetails.NewSingleOrderCondenced {
  overflow: hidden;
}

.orderDetails.newHomePageContainer {
  min-width: 75%;
}

.orderDetails.newContainer {
  padding: 0;
}

.orderDetails .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 40px;
}

.orderDetails a.bookMore {
  text-decoration: none;
  color: white;
  font-size: 18px;
  border: 1px solid white;
  padding: 8px;
  border-radius: 8px;
  width: 135px;
  margin: 0 auto;
}

.orderDetails .ordersWrapper {
  overflow-y: scroll;
  background-color: #000;
  height: calc(100vh - 318px);
}

.sidebar-secondLayout {
  display: flex;
  width: 100%;
}

.wrapper-orderdetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderDetails .wrapper-main {
  width: 50%;
  padding: 0 40px 40px;
}


.orderDetails .stepper {
  padding: 40px 0 10px 0;
  width: 100%;
}

.orderDetails .mapWrapper {
  height: 75%;
}

.orderDetails .margBot10 {
  margin-bottom: 10px;
}

.animationContainer {
  display: flex;
  align-items: center;
}

.animationContainer .cardOfComplete {
  width: 50%;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 550px;
}

.animationContainer.noBusinessOrder .cardOfComplete {
  justify-content: center;
}

.cardOfComplete .downloadApp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardOfComplete .downloadApp p {
  font-size: 22px;
}

.cardOfComplete .store-buttons {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardOfComplete .store-buttons .store-btn {
  width: 50px;
  color: white;
  background: transparent;
  border: none;
  margin-right: 32px;
}

.cardOfComplete .store-buttons .store-btn {
  width: 50px;
  color: white;
  background: transparent;
  border: none;
  margin-right: 32px;
}


.animationContainer .startSoonCard {
  width: 50%;
  padding: 40px;
  text-align: center;
}

.cardOfComplete h2 {
  font-size: 64px;
}

.cardOfComplete h3 {
  font-size: 24px;
}

.cardOfComplete p {
  font-size: 18px;
}

.home-img {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 0 auto;
}

.home-img .circleMain {
  position: absolute;
  z-index: 1;
  height: 430px;
  width: 430px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
  animation: spin_01 20s linear infinite;
  background-color: transparent;
}

.home-img .circleMain img {
  max-width: 80px;
  width: 100%;
  transform: translate(219%, -50%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 32px;
}

.circle-car {
  position: absolute;
  z-index: 1;
  height: 430px;
  width: 430px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  top: 0%;
  /* // left: 100%; */
  left: 0;
  right: 0;
  margin: auto;
  /* // transform: translate(-50%, -50%); */
  animation: spin_01 20s linear infinite;
}

.circle-car img {
  max-width: 80px;
  width: 100%;
  transform: translate(219%, -50%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 32px;
}

.home-background {
  opacity: 0.9;
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 0;
  height: 380px;
  width: 445px;
}

.phone-hero {
  max-width: 250px;
  width: 100%;
  left: 90px;
  /* box-shadow: var(--shadow-black-100); */
  position: absolute;
  border-radius: 32px;
  animation: bounceTop_01 3s ease infinite;
}

@keyframes spin_01 {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}

@keyframes bounceTop_01 {

  0%,
  to {
    transform: translateY(-30px)
  }

  50% {
    transform: translateY(0)
  }
}


/* MAIN SECTION CSS */

.orderDetails .detailsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.orderDetails .details,
.orderDetails .stepperSection {
  width: 50%;
}

.stepperSection .noProgressBar {
  text-align: center;
  font-size: 20px;
}

.orderDetails .detailSection {
  display: flex;
  gap: 1em;
  align-items: center;
}

.orderDetails .flex-column.idStatusTimeSection {
  margin-right: 16px;
  width: 25%;
}

.orderDetails .clientAndDriverInfoSection {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.orderDetails .clientInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.orderDetails .clientInfo.addBorderBottom {
  border-bottom: 1px solid dimgrey;
}

.orderDetails .clientName,
.orderDetails .clientAcc,
.orderDetails .service {
  width: 33.33%;
}

.orderDetails .driverName,
.orderDetails .vehicleName {
  width: 33.33%;
}

.orderDetails .driverName,
.orderDetails .vehicleName {
  width: 33.33%;
  height: 85px;
}

.orderDetails .textGrey {
  margin-bottom: 8px;
  font-size: 16px;
  color: darkgrey;
  font-weight: 600;
}

.orderDetails .clientNameText {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-size: 16px;
}

.orderDetails .driverInfo {
  display: flex;
  align-items: center;
  padding: 8px;
}

.orderDetails .vertCarSVG {
  margin-right: 7px;
  width: 35px;
}

.orderDetails .StatusBar-container {
  float: none;
  margin-left: 0;
}

.orderDetails .sectionTitle {
  font-size: 18px;
  border-bottom: 2px solid white;
  width: max-content;
  margin-bottom: 8px;
}

.moreInfoBtn {
  text-align: center;
}



/* Modal CSS */
.wrapperOfModal {
  background: black;
  color: white;
  border: 1px solid white !important;
}

.wrapperOfModal .dateInfo,
.wrapperOfModal .addressInfo,
.wrapperOfModal .airportInfo,
.wrapperOfModal .clientInfo,
.wrapperOfModal .noteInfo {
  border-bottom: 1px solid grey;
  padding: 16px;
}

.wrapperOfModal .clientInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapperOfModal .dateInfo {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 10px;
  /* font-size: 18px; */
}

.wrapperOfModal .dateInfo p {
  font-size: 16px;
}

.wrapperOfModal .airportInfo {
  display: flex;
  align-items: center;
  gap: 2em;
}

.airportInfo .StatusBar-container {
  float: none;
  margin: 0;
}

.wrapperOfModal .clientInfo div h6 {
  font-size: 16px;
}

.wrapperOfModal .mailInfo {
  font-size: 16px;
  text-align: center;
}

.wrapperOfModal .notesInput {
  width: 100%;
}

.orderDetails .carText {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}



.businessOrderList .moreInfoBtn {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderDetails.businessOrderList .detailsWrapper {
  border-bottom: 1px solid grey;
}

.orderDetails.businessOrderList .businessOrderListFilterBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: 10px 0 16px;
}

.orderDetails.businessOrderList .businessOrderListFilterBtns button {
  border: 2px solid;
}

.orderDetails.businessOrderList .wrapSelectAndBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.orderDetails.businessOrderList .wrapSelectAndBtns .wrappper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderDetails.businessOrderList .wrapSelectAndBtns .wrappper button {
  margin-left: 16px;
}

.orderDetails.businessOrderList #react-select-2-listbox {
  background: black;
  z-index: 100000;
}

.orderDetails.businessOrderList .selectForBusinessAdmin {
  width: 350px;
}

.orderDetails.businessOrderList .css-d7l1ni-option {
  color: black;
}
.viaLIs{
  height: auto !important;
  line-height: 1.2 !important;
}
.dialogCustonForMoreInfo .viaLIs {
  height: auto;
  line-height: 1.25 ;
}

.wrapperOfModal .wrapup p {
  color: darkgrey;
}

.wrapperOfModal .wrapup h6 {
  color: white;
}

/* .orderDetails */
/* NEW FILTERS */
.newUi-Filterwrapper .businessOrderListFilterBtns {
  display: none !important;
}

.orderFilterBoxWrapper .orderFilterBox-listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.newUi-Filterwrapper .orderFilterBoxWrapper {
  display: none;
}

.orderDetails.businessOrderList .businessOrderListFilterBtns {
  display: none;
}

.Header-top .header-main-logo-image {
  width: 200px;
}

.onOffWrapper {
  display: flex;
  align-items: center;
}

.sidebar-secondLayout {
  display: flex;
  height: calc(100vh - 302px);
}

/* .newUi-Filterwrapper .otherFilterWrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background: black;
  width: 50%;
} */

/* .Searchbar-container {
  background-color: #000 !important;
  width: 100%;
} */

.newUi-Filterwrapper .filtersTabs {
  background: black;
  padding: 5px;
  margin: 10px 0;
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
}

.filtersTabs .selectForOrderList {
  width: 50% !important;
}

/* .Searchbar-container.inputWithIcon {
  border: 1px solid white;
  margin-left: 5px;
  border-radius: 10px;
} */

.Searchbar-input.DatePicker-input {
  width: 25%;
  min-width: 135px;
}

.flex-column.assignDriverBtn {
  justify-content: center;
  width: 100%;
}

.flex-column.assignDriverBtn .Button-assign-content.custom-assign-driver {
  width: 120px;
}

.first-box.acordionDetailsIcons {
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  height: auto;
}

/* .first-box.acordionDetailsIcons .wrapperOfIconAtAll {
  width: 30px;
  height: 30px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} */

.sliding-panel.mainSlidingPanel {
  width: 500px !important;
}

.some-custom-overlay-class {
  z-index: 1000;
}

/* New Order Forms */

.newOrderFloatBtn {
  position: absolute !important;
  bottom: 3%;
  right: 5%;
  width: 260px;
  height: 48px;
  background-color: #1d1d1d !important;
  border-radius: 10px !important;
}

.newOrderFloatBtn:hover {
  background-color: #2a2929 !important;
}

.newOrderFloatBtn .iconOfPlus {
  color: white;
  display: none;
}

.New-container.mainUpperContainer {
  flex-direction: column;
  padding: 0px;
  overflow: scroll;
}

.allBtnWrappers {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.allBtnWrappers .assignCancleBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footerActionBtnsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

#react-select-3-listbox {
  color: black
}

.Assign-container {
  color: black;
}

.Assign-container.pastTripsContainer {
  width: auto;
  max-width: 75%;
  max-height: 92%;
  flex-direction: row;
}

.locationAndDetails {
  width: 100%;
}

.pastTripsContainer2 {
  /* display: flex; */
  overflow: scroll;
  height: 70%;
}

.filtersWrappers .wrapperOfBtns {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 50%;
  gap: 10px;
}

.filtersWrappers .wrapperOfBtns button {
  width: 100%;
}

.header-add-via {
  width: 100%;
}

.Header-cards-container.isMovView {
  display: none;
}

.Header-map-icon-container.statistic {
  display: none;
}

.header-icons-wrapper,
.Header-switcher-container {
  gap: 20px;
}

.ordersWrapper {
  padding-bottom: 110px;
}

.conversation-list-item:hover {
  background: #44484b;
  color: #eeeeee;
}

.compose {
  background: black;
  color: #eeeeee;
}

.compose-input {
  padding-left: 10px;
  color: #eeeeee;
  background: #44484b;
  border-radius: 8px;
}

input.compose-input::placeholder {
  color: white;
}

/* .message .bubble-container .bubble{
  color: #eeeeee;
} */
/* Hide the default scrollbar */
.newUiMessenger .scrollable::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

/* Track */
.newUiMessenger .scrollable::-webkit-scrollbar-track {
  background: #fff;
  /* Set the background color of the scrollbar track */
}

/* Handle */
.newUiMessenger .scrollable::-webkit-scrollbar-thumb {
  background: #888;
  /* Set the color of the scrollbar handle */
}

.accordian-newUi-details .topInformation {
  display: flex;
  width: 100%;
  padding: 8px;
}

.accordian-newUi-details .accordian-newUi-detailsWrapper {
  flex: 1 0 30%;
  border-right: 1px solid grey;
  padding: 0 10px;
}
.accordian-newUi-detailsWrapper .wrapup div h6, 
.accordian-newUi-detailsWrapper .wrapup > h6 {
  font-size: 16px;
}
.accordian-newUi-detailsWrapper .wrapup p h6 {
  font-size: 16px;
}
/* .accordian-newUi-details .wrapperOfIconAtAll {
  padding: 8px;
} */
.orderDetails .detailsWrapper{
  margin-bottom: 0;
}
.bottomInformation {
  padding: 0 16px 16px;
}
.detailsWrapper.borderClassOverall, .accordian-newUi-details.borderClassOverall{
  border: 2px solid grey;
}
@media only screen and (max-width: 1440px) {
  .orderDetails.NewSingleOrderCondenced .stepperSection {
    width: 55%;
    margin-left: 60px;
  }
}
@media only screen and (max-width: 1400px) {
  .pastTripsContainer .singleTrip {
    width: 48%;
  }
}

/* NEW HOME PAGE NEW CHANGES START */
.orderDetails .details {
  width: 60%;
}
.orderDetails .stepperSection {
  width: 40%;
}
.orderDetails .detailSection{
  gap: 0;
}
.orderDetails .flex-column.idStatusTimeSection {
  margin-right: 8px;
}
.orderDetails .driverName, .orderDetails .vehicleName {
  height: 64px;
}
.orderDetails .vertCarSVG {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderDetails .driverName {
  margin-left: 8px;
}
.orderDetails .clientInfo.addBorderBottom {
  border-bottom: none;
}
/* NEW HOME PAGE NEW CHANGES FINISH */


/* Condenced new ui for enroute, arrived, pob */
.orderDetails.NewSingleOrderCondenced .driverInfo {
  display: none;
}
.orderDetails.NewSingleOrderCondenced .wrapper .driverInfo {
  display: flex;
  position: absolute;
  z-index: 10;
  width: 450px;
  /* height: 80%; */
  right: -50px;
  z-index: 1000;
  background: #1d1d1d;
  top: -5%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  height: auto;
}
.orderDetails.NewSingleOrderCondenced .wrapper .driverInfo.showInRight{
  left: 0%;
}
.orderDetails.NewSingleOrderCondenced .wrapper .driverName, .orderDetails.NewSingleOrderCondenced .wrapper .vehicleName {
  padding-top: 0;
  width: auto;
  height: auto;
}
.orderDetails.NewSingleOrderCondenced .wrapper {
  height: 87px;
}
.orderDetails.NewSingleOrderCondenced .vertCarSVG{
  width: 25px;
}
.first-box.acordionDetailsIcons.condencedUI {
  width: 100%;
  background: #1d1d1d;
  padding: 10px;
  color: black;
  border-radius: 8px;
}
/* .condencedUI.first-box.acordionDetailsIcons .wrapperOfIconAtAll {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  color: white;
  margin: 10px;
} */
.popoverMenuIcons .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  background: transparent;
}
.NewSingleOrderCondenced .first-box.acordionDetailsIcons {
  display: none;
}
.NewSingleOrderCondenced .first-box.acordionDetailsIcons.show{
  display: flex;
}
.orderDetails.NewSingleOrderCondenced .driverInfo.show{
  display: flex;
}
.orderDetails.NewSingleOrderCondenced .details.width50{
  width: 50%;
}
.orderDetails.NewSingleOrderCondenced .flex-column.idStatusTimeSection.flex25{
  flex: 0 0 25%;
}
.orderDetails.NewSingleOrderCondenced .stepperSection.width45 {
  width: 45%;
  margin-left: 0;
}
.NewSingleOrderCondenced .wrapper .first-box.acordionDetailsIcons {
  display: flex;
  height: 30px;
  margin: 10px;
  width: 100%;
  justify-content: space-evenly;
}
.NewSingleOrderCondenced .wrapper .first-box.acordionDetailsIcons .wrapperOfIconAtAll{
  background: transparent;
}
.NewSingleOrderCondenced .firstDriverInfoWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.NewSingleOrderCondenced .moreHorizIcon{
  cursor: pointer;
  margin: 10px;
}

.orderDetails.NewSingleOrderCondenced .clientInfo.addBorderBottom {
  border: none;
}

.NewSingleOrderCondenced .newUi-Filterwrapper .filtersTabs {
  margin: 10px 0 0;
}
.NewSingleOrderCondenced .clientAndDriverInfoSection {
  display: none;
}
.NewSingleOrderCondenced .clientAndDriverInfoSection.show {
  display: block;
  flex: 0 0 75%;
}
.NewSingleOrderCondenced .serviceAndOtherSection.hide{
  display: none;
}
.NewSingleOrderCondenced .serviceAndOtherSection {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 45%;
  height: 100%;
  /* flex: 1 0 150px; */
  align-items: center;
  padding: 0 10px;
}
.serviceAndOtherSection .clientAcc, .serviceAndOtherSection .service {
  width: auto;
}
.orderDetails.NewSingleOrderCondenced .textGrey {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
}
.orderDetails.NewSingleOrderCondenced .details {
  width: 25%;
}
.orderDetails.NewSingleOrderCondenced .stepperSection {
  width: 65%;
}
.orderDetails.NewSingleOrderCondenced .flex-column.idStatusTimeSection, .orderDetails.NewSingleOrderCondenced .clientAndDriverInfoSection {
  flex: 1 0 150px;
}

/* Schedule accepted */
.detailsWrapper.scheduled_accepted .details.width50 {
  width: 65%;
}
.detailsWrapper.scheduled_accepted .clientAndDriverInfoSection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 0 0 100%;
}
.detailsWrapper.scheduled_accepted .clientInfo.addBorderBottom {
  display: flex;
  gap: 1em;
}
.detailsWrapper.scheduled_accepted .clientAcc {
  flex: 0 0 50%;
}
.detailsWrapper.scheduled_accepted .service {
  flex: 0 0 50%;
}
.detailsWrapper.scheduled_accepted .driverInfo.show {
  display: flex;
  border-bottom: none;
  width: 50%;
}
.detailsWrapper.scheduled_accepted .stepperSection.width45 {
  margin-left: 0;
  width: 30%;
}
.detailsWrapper.scheduled_accepted .first-box.acordionDetailsIcons.show {
  display: flex;  
  width: 100%;
  justify-content: space-evenly;
}

/* Status schedule/new */
.detailsWrapper.scheduled .stepperSection.width45 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.detailsWrapper.scheduled .flex-column.assignDriverBtn {
  width: auto;
}
.orderDetails.NewSingleOrderCondenced .wrapper.destinationWith {
  position: absolute;
  height: auto;
  max-width: 500px;
  min-width: 350px;
  background: #1D1D1D;
  padding: 10px;
  right: -5px;
  z-index: 1;
  border-radius: 10px;
  top: -5px;
}

/* Login page admin login changes */
.Login-container span.New-title {
  font-size: 24px;
}
.Login-container select.FormField__Input {
  border: 1px solid #000;
  padding: 10px;
  margin: 16px;
  border-radius: 8px;
}

/* New mobile changes */
.orderDetails .mobileView.detailsWrapper {
  display: none;
}
.orderDetails .desktopView.detailsWrapper.oldOne {
  display: none;
}

.orderDetails.NewSingleOrderCondenced .driverInfo.newCondencedUI{
  display: flex;
}

.orderDetails.NewSingleOrderCondenced .sentToDriversInfo{
  font-size: 14px;
  text-align: center;
  display: flex;
  gap: 4px;
}

.detailsWrapper.desktopView.newOne .newLayoutWrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .flex-column {
  flex: 0 0 18%;
  margin-right: 0;
}

.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew {
  display: flex;
  flex-direction: column;
  width: 82%;
  height: 108px;
  /* padding: 0 10px; */
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne.openPopOver.withProgress .sideLayoutNew{
  height: auto;
}

.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew .topSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #1d1d1d;
  align-items: center;
  height: 54px;
  padding: 0 10px;
  gap: 2em;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew .topSection .topSectionChild{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew .topSection.spec .topSectionChild{
  width: 50%;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .bottomSectionNew {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 0 10px;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .topSectionText {
  font-size: 16px;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .first-box.acordionDetailsIcons.show {
  height: 100%;
}

.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .otherNewInfoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-right: 10px;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew.showRowInSomeStatus {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .first-box.acordionDetailsIcons.show {
  width: 40%;
} */
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .stepper {
  width: 60%;
  /* padding: 30px 0 2px 0; */
  padding: 0;
  margin: 30px;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .stepper.width100 {
  width: 100%;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .eta-wrapper.forBottomBar{
  position: static;
  flex-direction: row;
  padding: 5px 8px;
  width: auto;
  height: fit-content;
}
.sideLayoutNew .StatusBar-container {
  margin-bottom: 0;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .Button-assign-content.custom-assign-driver {
  margin-top: 0;
  width: auto;
  padding: 14px;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .eta-wrapper.forBottomBar.rmvbg{
  background: transparent;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .eta-wrapper.forBottomBar span.eta-mins {
  width: max-content;
}
.orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne img.conversation-photo {
  width: 35px;
  height: 35px;
}

.topSectionTextChild {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topSectionTextChild svg{
  margin-left: 10px;
  overflow: visible;
  z-index: 100;
}

@media only screen and (max-width: 1300px) {
  .orderDetails .Header-time-container {
    display: none;
  }

  .orderDetails .Header-map-icon-container {
    display: block;
  }
  
  .Header-map-icon-container.statistic {
    display: none;
  }
  
  .sidebar-secondLayout {
    width: 100%;
  }

  .mainMapContainer {
    display: none;
  }

  .mainMapContainer.visibleInMob {
    display: block;
  }

  /* .orderDetails.newContainer .Sidebar-container {
    width: 100%;
  } */

  .orderDetails.orderDetails.newContainer .orderDetails .ordersWrapper {
    width: "100%";
  }
  
  .orderDetails .detailSection {
    margin: 0 8px;
  }
  
  .newOrderFloatBtn {
    width: 75px;
    height: 75px;
    background-color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 50% !important;
    -webkit-box-shadow: -1px -2px 29px 21px rgba(255, 255, 255, 0.8) !important;
    -moz-box-shadow: -1px -2px 29px 21px rgba(255, 255, 255, 0.8);
    box-shadow: -1px -2px 24px -3px rgba(255, 255, 255, 0.7) !important;
  }
  
  .newOrderFloatBtn:hover {
    background-color: rgb(209, 206, 206) !important;
  }
  
  .newOrderFloatBtn .iconOfPlus {
    color: black;
    display: block;
    font-size: 50px;
    font-weight: 900;
  }
  
  .newOrderFloatBtn .textOfAddNew {
    display: none;
  }
  
  .header-add-via {
    flex-direction: column;
    width: 100%;
  }
  
  
  .header-add-via .filtersWrappers {
    width: 100%;
  }
}

@media only screen and (max-width: 1240px) {
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew{
    height: auto;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .bottomSectionNew {
    flex-direction: column;
    gap: 4px;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .otherNewInfoBlock{
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .mainUpperContainer {
    flex-direction: column;
    padding: 0px;
    width: 740px;
    height: auto;
  }

  .mainLowerContainer {
    flex: 1 0 auto;
    overflow-x: scroll;
  }

  .New-container .new-order-container-child {
    flex: 1 0 auto;
  }
  
}

@media only screen and (max-width: 1024px) {
  .Assign-container.pastTripsContainer {
    max-width: 90%;
  }

  .orderDetails {
    padding: 0px 40px 40px;
  }

  .animationContainer {
    overflow: hidden;
  }

  .detailsWrapper.scheduled_accepted .details.width50 {
    width: 100%;
  }
  .orderDetails.businessOrderList .detailsWrapper {
    flex-direction: column;
  }
  .orderDetails.NewSingleOrderCondenced .details.width50 {
    width: 100%;
  }
  .orderDetails.businessOrderList .detailsWrapper.withProgress {
    flex-direction: row;
  }
  .detailsWrapper.scheduled_accepted {
    flex-direction: column;
  }
  .detailsWrapper.scheduled_accepted .details.width50 {
    width: 100%;
  }

  .detailsWrapper.scheduled .stepperSection.width45 {
    width: 75%;
  }
  .detailsWrapper.scheduled_accepted .stepperSection.width45 {
    width: 50%;
  }

  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .serviceAndOtherSection{
    flex-direction: column;
    width: auto;
  }

  .Header-cards-container{
    height: auto;
  }

  .orderDetails .dispatchePage .details {
    width: 100%;
  }
}

@media only screen and (max-width: 994px) {
  .animationContainer .cardOfComplete {
    padding: 30px;
  }
  .accordian-newUi-details{
    display: none;
  }
  .cardOfComplete h2 {
    font-size: 44px;
    margin-bottom: 16px;
  }

  .cardOfComplete h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .cardOfComplete p {
    font-size: 18px;
  }

  .newUi-Filterwrapper .filtersTabs {
    display: block;
  }

  .filtersTabs .selectForOrderList {
    width: 40%;
  }

  /* .newUi-Filterwrapper .otherFilterWrapper {
    width: 100%;
  } */

  .orderDetails {
    padding: 0 40px;
  }

  .wrapper-orderdetails {
    display: block;
  }

  .orderDetails .wrapper-main {
    width: 100%;
  }

  .animationContainer {
    flex-direction: column-reverse;
    padding-top: 50px;
  }

  .orderDetails .stepper {
    width: 100%;
  }

  .home-img {
    width: 430px;
    height: 430px;
  }

  .animationContainer .cardOfComplete {
    width: 100%;
  }

  .orderDetails .detailsWrapper {
    display: block;
  }

  .orderDetails .details,
  .orderDetails .stepperSection {
    width: 100%;
  }

  .orderDetails.orderDetailsPage .details,
  .orderDetails.orderDetailsPage .stepperSection {
    width: 100%;
    margin-bottom: 15px;
  }

  .orderDetails .moreInfoBtn {
    margin-top: 16px;
  }

  .orderDetails .ordersWrapper {
    height: calc(100vh - 412px);
  }

  .orderDetails.NewSingleOrderCondenced .flex-column.idStatusTimeSection, .orderDetails.NewSingleOrderCondenced .clientAndDriverInfoSection {
    flex: 0 0 150px;
  }
  .NewSingleOrderCondenced .serviceAndOtherSection {
    flex: 0 0 150px;
  }
  .orderDetails.NewSingleOrderCondenced .stepperSection {
    width: 100%;
    margin-left: 0;
  }
  /* .NewSingleOrderCondenced .first-box.acordionDetailsIcons {
    display: flex;
  } */
  /* .NewSingleOrderCondenced .moreHorizIcon {
    display: none;
  } */
  .orderDetails.NewSingleOrderCondenced .detailSection {
    justify-content: space-evenly;
  }
  .orderDetails.NewSingleOrderCondenced .details {
    width: 100%;
  }
  .orderDetails.NewSingleOrderCondenced .details.width50{
    width: 100%;
  }
  .orderDetails.NewSingleOrderCondenced .stepperSection.width45{
    width: 100%;
  }
  .NewSingleOrderCondenced .clientAndDriverInfoSection.show{
    flex: 0 0 75%;
  }
  
  .orderDetails.NewSingleOrderCondenced .stepperSection.width45 {
    flex-direction: column;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .topSectionText {
    font-size: 14px;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .carText {
    font-size: 14px;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew .topSection {
    flex-direction: column;
    height: 70px;
    justify-content: center;
    gap: 3px;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew .topSection.spec .topSectionChild,
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew .topSection .topSectionChild {
    width: 100%;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .otherNewInfoBlock {
    width: 100%;
  }
  .orderDetails.orderDetailsPage .stepper {
    border-bottom: 1px solid grey;
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 820px) {

}

@media only screen and (max-width: 768px) {
  /* .orderDetails .stepper {
    border-bottom: 1px solid grey;
  } */
  .NewSingleOrderCondenced .first-box.acordionDetailsIcons {
    display: flex;
  }
  .NewSingleOrderCondenced .moreHorizIcon {
    display: none;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .stepper {
    width: 60%;
    padding: 30px 0 30px 0;
    /* padding: 0; */
    margin: 0px;
  }

  .NewSingleOrderCondenced .serviceAndOtherSection {
    display: none;
  }
  .orderDetails.NewSingleOrderCondenced .flex-column.idStatusTimeSection, .orderDetails.NewSingleOrderCondenced .clientAndDriverInfoSection {
    flex: 1 0 auto;
  }
  .orderDetails.NewSingleOrderCondenced .detailSection{
    justify-content: flex-start;
  }
  .orderDetails .clientInfo {
    justify-content: space-evenly;
  }
  .orderDetails.businessOrderList .wrapSelectAndBtns {
    display: block;
  }

  .orderDetails.businessOrderList .wrapSelectAndBtns .wrappper {
    margin: 10px;
  }

  .orderDetails.newContainer {
    overflow: hidden;
  }

  .New-container.mainUpperContainer {
    width: 90%;
    height: auto;
  }

  .New-container.mainLowerContainer {
    overflow: visible;
    flex-direction: column;
    overflow-y: scroll;
    height: 612px;
  }

  .New-container .new-order-container-child {
    width: auto;
  }

  .allBtnWrappers {
    flex-direction: column;
    width: 100%;
  }

  .footerActionBtnsWrapper {
    flex-direction: column;
  }

  .allBtnWrappers .assignCancleBtns {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .showPastTripsBtn {
    text-align: center;
    margin-bottom: 10px;
  }

  .pastTripsContainer .singleTrip {
    width: 100%;
    padding: 10px;
  }

  .pastTripsContainer .singleTrip {
    width: 100%;
    margin-bottom: 8px;
  }

  .Assign-container.pastTripsContainer {
    max-width: 90%;
    padding: 10px;
  }

  .pastTripsContainer.pastTripsContainer3 {
    overflow: auto;
  }

  .header-add-via .filtersWrappers {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .pastTripsContainer span.Assign-title {
    margin-bottom: 10px;
  }

  .sliding-panel.mainSlidingPanel {
    width: 400px !important;
  }

  .OrderPopUp-container {
    width: 80% !important;
  }

  /* Condenced new ui for enroute, arrived, pob */
  .orderDetails.NewSingleOrderCondenced .driverInfo {
    display: flex;
  }
  .NewSingleOrderCondenced .clientName{
    display: none;
  }

  .NewSingleOrderCondenced .first-box.acordionDetailsIcons {
    display: flex;
  }

  .orderDetails.NewSingleOrderCondenced .clientInfo.addBorderBottom {
    border-bottom: 1px solid dimgrey;
  }

  .NewSingleOrderCondenced .newUi-Filterwrapper .filtersTabs {
    margin: 10px 0 0;
  }

  .NewSingleOrderCondenced .clientAndDriverInfoSection {
    display: flex;
  }

  .orderDetails.NewSingleOrderCondenced .clientAndDriverInfoSection {
    flex-direction: column;
  }
  .orderDetails.NewSingleOrderCondenced .clientInfo.addBorderBottom {
    justify-content: space-evenly;
  }
  .detailsWrapper.scheduled_accepted .clientAcc {
    flex: 0 0 33.33%;
  }
  .detailsWrapper.scheduled_accepted .service {
    flex: 0 0 33.33%;
  }
  .detailsWrapper.scheduled_accepted .driverInfo.show {
    width: 100%;
    border-bottom: 1px solid dimgrey;
  }


  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .sideLayoutNew {
    height: auto;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .bottomSectionNew {
    flex-direction: column;
    height: 100%;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .first-box.acordionDetailsIcons.show {
    width: 90%;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .stepper {
    width: 100%;
  }

  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne .otherNewInfoBlock{
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media only screen and (max-height: 1024px) and (max-width: 768px) {
  .New-container.mainLowerContainer {
    height: 540px;
  }
}

@media only screen and (max-height: 768px) and (max-width: 768px) {
  .New-container.mainLowerContainer {
    height: 490px;
  }
}

@media only screen and (max-height: 667px) and (max-width: 768px) {
  .New-container.mainLowerContainer {
    height: 440px;
  }
}

@media only screen and (max-width: 700px) {
  .sidebar-sidecontent {
    display: none;
  }

  .sidebar-secondLayout .orderFilterBoxWrapper {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding: 0;
  }

  .sidebar-secondLayout .orderFilterBoxWrapper::-webkit-scrollbar {
    display: none;
  }

  .Map-header-cards-container::-webkit-scrollbar {
    display: none;
  }

  .sidebar-secondLayout .orderFilterBoxWrapper .noMapContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .orderFilterBoxWrapper .orderFilterBox-listItem {
    justify-content: flex-start;
    padding: 8px 16px 0;
    margin: 0;
  }

  .orderDetails.businessOrderList .businessOrderListFilterBtns {
    display: flex !important;
    margin: 5px 0 5px;
  }

  .orderFilterBoxWrapper .orderFilterBox-listItem .css-1tsvksn {
    width: 100%;
    text-align: center;
  }

  .newUi-Filterwrapper .filterTextWrapper {
    display: none;
  }

  .Header-switcher-text.newUiOnOffText {
    color: #000;
  }

  .newUi-Filterwrapper .filtersTabs {
    background: black;
    padding: 0 0 5px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
  }

  .newContainer .box-with-border {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .Header-cards-container.isMovView {
    display: flex;
  }

  .Header-cards-container.isDesktopView {
    display: none;
  }

  .Header-map-icon-container.statistic {
    display: block;
  }

  .header-icons-wrapper,
  .Header-switcher-container {
    gap: 10px;
  }

  .sidebar-secondLayout {
    height: calc(100vh - 92px);
  }

  .orderDetails .ordersWrapper {
    height: calc(100vh - 294.5px);
  }

  .sidebar-secondLayout.topAndFilterVisible {
    height: calc(100vh - 292px);
  }

  .ordersWrapper.topAndFilterVisible {
    height: calc(100vh - 511px);
  }

  .sidebar-secondLayout.topCardsVisible {
    height: calc(100vh - 292px);
  }

  .ordersWrapper.topCardsVisible {
    height: calc(100vh - 292px);
  }

  .sidebar-secondLayout.onlyOrdersVisible {
    height: calc(100vh - 92px);
  }

  .ordersWrapper.onlyOrdersVisible {
    height: calc(100vh - 92px);
  }

  .airportInfo {
    flex-wrap: wrap;
    justify-content: center;
    /* flex: 1; */
  }
}

@media only screen and (max-width: 576px) {
  .orderDetails .iconToHide {
    display: none;
  }

  .orderDetails .topbar {
    padding: 0;
  }

  .cardOfComplete h2 {
    font-size: 36px;
    margin-bottom: 12px;
  }

  .cardOfComplete h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .cardOfComplete p {
    font-size: 16px;
  }

  .home-img {
    width: 300px;
    height: 300px;
  }

  .home-img .circleMain {
    height: 300px;
    width: 300px;
    top: 0%;
    left: 0%;
  }

  .home-img .circleMain img {
    max-width: 50px;
  }

  .phone-hero {
    max-width: 180px;
    width: 100%;
    left: 62px;
  }

  .home-background {
    top: 25px;
    left: 9px;
    height: 250px;
    width: 300px;
  }

  .animationContainer .cardOfComplete {
    height: auto;
  }

  .animationContainer {
    padding-top: 30px;
  }

  .cardOfComplete .downloadApp p {
    font-size: 16px;
  }

  .cardOfComplete .store-buttons .store-btn {
    width: 32px;
  }

  .orderDetails {
    padding: 0 30px;
  }


  .orderDetails .mapWrapper {
    height: 65%;
  }

  .wrapper-orderdetails {
    height: 35%;
  }

  .orderDetails .wrapper-main {
    padding: 0;
  }

  .orderDetails .flex-column {
    width: 24%;
  }

  .orderDetails .flex-column h6 {
    font-size: 16px;
  }

  .orderDetails .flex-column p {
    font-size: 12px;
  }

  .orderDetails .flex-column span {
    font-size: 13px;
  }

  .orderDetails .flex-column button {
    font-size: 12px;
    padding: 3px 8px;
    margin-top: 10px;
  }

  .orderDetails .flex-column .StatusBar-container {
    margin: 10px 0;
    min-width: 100px;
  }

  .orderDetails .flex-column p h6 {
    font-size: 13px;
  }

  .orderDetails .flex-column .vertCarSVG {
    margin-right: 5px;
    width: 30px;
  }

  .orderDetails .margBot10 {
    margin-bottom: 8px;
  }

  .orderDetails.businessOrderList .detailsWrapper {
    padding-top: 16px;
    border: 1px solid grey;
    border-radius: 10px;
  }

  .orderDetails.businessOrderList .flex-column {
    height: 100%;
  }

  .orderDetails.businessOrderList .detailSection {
    height: auto;
  }

  .orderDetails.businessOrderList .stepper {
    padding: 30px 20px;
  }

  .orderDetails.businessOrderList .stepperSection {
    margin-top: 0px;
  }

  .flex-column.assignDriverBtn {
    width: 100%;
  }

  .first-box.acordionDetailsIcons {
    width: 100%;
    justify-content: space-evenly;
  }

  .Header-top .header-main-logo-image {
    width: 120px;
  }

  .chat-text {
    display: none;
  }

  .Header-switcher-text.newUiOnOffText {
    color: #000;
  }

  .newOrderFloatBtn {
    width: 64px;
    height: 64px;
  }

  .newOrderFloatBtn .iconOfPlus {
    font-size: 40px;
  }

  .pastTripsContainer2 {
    overflow: auto;
  }

  .pastTripsContainer2::-webkit-scrollbar {
    display: none;
  }

  .Assign-container.pastTripsContainer::-webkit-scrollbar {
    display: none;
  }

  .orderDetails .ordersWrapper::-webkit-scrollbar {
    display: none;
  }

  .header-add-via .filtersWrappers .Searchbar-container {
    width: auto;
    overflow: hidden;
    margin: 0;
  }

  .header-add-via .filtersWrappers .Searchbar-input {
    font-size: 12px;
  }

  .Avatar {
    width: 35px !important;
    height: 35px !important;
    min-width: 35px !important;
  }

  .sidebar-secondLayout {
    height: calc(100vh - 75px);
  }

  .orderDetails .ordersWrapper {
    height: calc(100vh - 296px);
  }

  .sidebar-secondLayout.topAndFilterVisible {
    height: calc(100vh - 275px);
  }

  .ordersWrapper.topAndFilterVisible {
    height: calc(100vh - 496px);
  }

  .sidebar-secondLayout.topCardsVisible {
    height: calc(100vh - 275px);
  }

  .ordersWrapper.topCardsVisible {
    height: calc(100vh - 275px);
  }

  .sidebar-secondLayout.onlyOrdersVisible {
    height: calc(100vh - 75px);
  }

  .ordersWrapper.onlyOrdersVisible {
    height: calc(100vh - 75px);
  }

  .sliding-panel.mainSlidingPanel {
    width: 100% !important;
  }

  .OrderPopUp-container {
    width: 90% !important;
  }

  /* Chat Media Query */
  .newUiMessenger.messenger {
    display: flex;
  }

  .newUiMessenger .scrollable.content {
    display: none;
  }

  .newUiMessenger .scrollable.sidebar {
    width: 100%;
  }

  .scrollable.sidebar.hideChatBox {
    display: none;
  }

  .scrollable.content.showChatBox {
    display: block;
    width: 100%;
  }

  .arrowBackMessangerList {
    display: inline-block !important;
    margin: 10px;
    cursor: pointer;
  }

  .compose {
    width: 100%;
  }

  .orderDetails .flex-column h6 {
    font-size: 13px;
  }

  .flex-column .blackBox{
    width: 146px !important;
  }
  .orderDetails.NewSingleOrderCondenced .detailsWrapper.desktopView.newOne {
    padding-top: 0;
  }
}

@media only screen and (max-width: 524px) {
  .flex-column .blackBox{
    width: 186px !important;
  }

  .orderDetails {
    padding: 0 30px;
    padding: 0 15px;
  }

  .orderDetails .detailSection {
    gap: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 325px;
  }

  .orderDetails .flex-column.idStatusTimeSection {
    margin-right: 0px;
    width: 60%;
  }

  .orderDetails .clientAndDriverInfoSection {
    width: 100%;
  }

  .orderDetails .driverName,
  .orderDetails .vehicleName {
    width: 33.33%;
  }

  .dialogCustonForMoreInfo .MuiPaper-elevation {
    margin: 16px;
  }

  .wrapperOfModal .airportInfo {
    gap: 1em;
  }

  .wrapperOfModal .clientInfo h6 {
    font-size: 14px;
  }

  .orderDetails .stepperSection {
    width: 100%;
    margin-top: 16px;
  }

  .wrapperOfModal .dateInfo,
  .wrapperOfModal .addressInfo,
  .wrapperOfModal .airportInfo,
  .wrapperOfModal .clientInfo,
  .wrapperOfModal .noteInfo {
    padding: 16px 0px;
  }

  .wrapperOfModal .clientInfo {
    flex-wrap: wrap;
    gap: 1em;
  }

  .wrapperOfModal .clientInfo div h6 {
    font-size: 14px;
  }

  .wrapperOfModal .wrapup {
    flex: 1 0 30%;
  }

  /* New mobile changes */
  .orderDetails .mobileView.detailsWrapper {
    display: block;
  }
  /* New mobile changes */
  .desktopView.detailsWrapper {
    display: none;
  }
  .mobileView.detailsWrapper .topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    color: white;
    background: #2d2d2d;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
  }
  .mobileView.detailsWrapper .StatusBar-container {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .mobileView.detailsWrapper .otherNewInfoBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #2d2d2d;
    padding: 5px;
  }
  .mobileView.detailsWrapper .eta-wrapper.forBottomBar {
    position: static;
    flex-direction: row;
    padding: 5px 8px;
    width: auto;
  }
  .mobileView.detailsWrapper .eta-wrapper.forBottomBar.rmvbg {
    background-color: transparent;
  }
  .mobileView.detailsWrapper.openPopOver {
    padding-top: 0 !important;
  }
  .mobileView.detailsWrapper .Button-assign-content.custom-assign-driver {
    margin: 0;
    padding: 10px;
    width: auto;
  }
  .driverPhoto .conversation-photo {
    width: 25px;
    height: 25px;
  }

  .orderDetails.businessOrderList .wrapSelectAndBtns .wrappper{
    margin: 10px 0;
  }
}

@media only screen and (max-width: 500px) {
  .orderDetails .dispatchePage .driverInfo {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 490px) {
  .mobileView.detailsWrapper .otherNewInfoBlock{
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 4px;
  }
}

@media only screen and (max-width: 425px) {
  .orderDetails .clientNameText {
    font-size: 14px;
  }

  .orderDetails .driverInfo h6 {
    font-size: 14px;
  }

  .orderDetails .carText {
    font-size: 14px;
  }

  .wrapperOfModal .wrapup p {
    font-size: 14px;
  }

  .Header-top .header-main-logo-image {
    width: 100px;
  }

  .OrderPopUp-container {
    width: 95% !important;
  }

  .OrderPopUp-estimation-text,
  .OrderPopUp-estimation {
    font-size: 18px !important;
  }
}