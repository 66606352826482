.toolbar {
  display: flex;
  align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: sticky;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.left-items, .right-items {
  flex: 1;
  padding: 10px;
  display: flex;
  cursor: pointer;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}