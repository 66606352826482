.pastTripsContainer {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  justify-content: flex-start;
  overflow: scroll;
}

.pastTripsContainer .singleTrip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 32%;
  border: 3px solid rgba(128, 128, 128, 0.5);
  border-radius: 12px;
  margin: 6px;
  padding: 8px;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.75);
}

.pastTripsContainer .singleTrip:hover {
  background-color: #dadada;
}

.pastTripsContainer .singleTrip.selected {
  border: 3px solid #28579a;
}

.singleTrip .datePrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.singleTrip .type {
  display: flex;
}

.singleTrip .type .StatusBar-container {
  float: none;
  margin: 0 10px 0 0;
}

.singleTrip .viaLIs {
  height: 35px;
}

.pastTripsContainer2 {
  /* display: flex; */
  overflow: scroll;
}

.header-add-via .filtersWrappers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  gap: 0.5em;
}

/* .header-add-via .filtersWrappers .Searchbar-input{
  width: auto;
  padding-left: 0;
} */